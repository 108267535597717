<template>
    <div class="fixed inset-0" :class="z" :show="open"> <!-- overflow-y-auto -->
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                :class="open ? 'ease-out duration-300 opacity-100' : 'ease-in duration-200 opacity-0'"/>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                :class="[z, open ? 
                    'ease-out duration-300 opacity-100 translate-y-0 sm:scale-100 ' 
                    : 'ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 ',
                    mq.xs ? 'w-full mb-4' : '']">
                <div class="sm:flex sm:items-start">
                    <!-- <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10
                        bg-sky-200">
                        <FolderIcon class="h-6 w-6 text-sky-600" aria-hidden="true" />
                    </div> -->
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                        <h3 as="h3" class="text-lg leading-6 font-medium text-gray-900">{{ title || ''}}</h3>
                        <div class="mt-2">
                            <!-- <p class="text-sm text-gray-500">{{ message || '' }}</p> -->
                            <p class="text-sm text-gray-500" v-html="message"></p>
                        </div>
                        <div v-if="input" class="mt-3 w-full">
                            
                            <input v-if="input.type === 'text'" :type="input.type" class="shadow-sm focus:ring-0 block w-full sm:text-sm border-gray-300 rounded-md" 
                                :placeholder="input.placeholder" v-model="input.value" @keyup.enter="triggerSubmit" ref="inputField">
                            <FormInput v-else :type="input.type" v-model="input.value" :placeholder="input.placeholder" :settings="input.settings||{}"></FormInput>
                            
                        </div>
                        <div v-if="err" class="mt-2 mb-2 w-full text-red-700 text-sm">
                            <span v-if="err == 'inputEmpty'">
                                Bitte gib einen Namen an.
                            </span>
                            <span v-else>{{ err }}</span>
                        </div>
                        <div v-if="input?.hint" class="mt-2 mb-2 w-full text-gray-500 text-sm">
                            {{ input.hint }}
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:flex sm:flex-row-reverse"> <!-- font-medium -->
                    <button v-for="(a,i) in actions" :key="`dialog-action-${i}-${a.label}`" type="button"
                        @click="triggerAction(a.action)" :class="[
                            'w-full inline-flex justify-center rounded-md border shadow-sm py-1.5 text-base sm:mt-0 sm:w-auto sm:text-sm sm:ml-3 focus:outline-none',
                            a.type === 'cancel' ? 'px-3 border-gray-300 text-gray-700 hover:bg-gray-100' : 'px-4 text-white border-transparent',
                            mq.xs ? 'mt-2' : '',
                            {
                                'bg-sky-600 hover:bg-sky-700': a.type === 'sky',
                                'bg-red-600 hover:bg-red-700': a.type === 'red',
                                'bg-orange-500 hover:bg-orange-600': a.type === 'orange',
                                'bg-green-600 hover:bg-green-700': a.type === 'green',
                                'bg-slate-600 hover:bg-slate-700': a.type === 'slate'
                            }
                        ]">
                        <svg v-if="loading && a.type !== 'cancel'" class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="3"></circle>
                            <path class="opacity-60" fill="currentColor" d="M3 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span v-else>{{ a.label }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { QrcodeIcon, FolderIcon } from '@heroicons/vue/outline'
import { useMainStore } from '/src/stores/main'
import FormInput from './FormInputNew.vue'
import { useMq } from "vue3-mq"
const mq = useMq()
const props = defineProps({
    title: String,
    message: String,
    icon: String,
    input: {type: Object, default: null},
    actions: {type: Array, default: []},
    z: {type: String, default: 'z-20'}
})
const open = ref(false)
const loading = ref(false)
const err = ref('')
const inputField = ref()
const mainStore = useMainStore()
onMounted(() => {
    setTimeout(() => {
        open.value = true;
        if(props.input && props.input.type === 'text') inputField.value.focus()
    }, 100);
});
const closeModal = () => {
    open.value = false;
    setTimeout(() => {
        mainStore.dialogs.splice(-1)
    }, 500);
}
const triggerAction = (action) => {
    if(action && props.input && props.input.required && !props.input.value) {
        err.value = 'inputEmpty'
        return;
    }
    if(action) {
        loading.value = !loading.value
        err.value = ''
        action(props.input?.value || null).then(e => {
            if(e) {
                err.value = e
                loading.value = false
            } else {
                closeModal()
            }
        })
    } else {
        closeModal()
    }
}
const triggerSubmit = () => {
    triggerAction(props.actions[0].action)
}
// const showError = (msg) => {
//     loading.value = false
//     err.value = msg
// }
// defineExpose({
//     showError
// })
</script>